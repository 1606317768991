<template>
    <div class="demo flex">
        <div class="bg flex-1 flex flex-v">
            <div class="the-header flex flex-m flex-between">
                <div><img src="@/assets/the-header-logo-sk.png"></div>
            </div>
            <div class="main flex-1 flex flex-m flex-t">
                <div class="box flex">
                    <div class="left myfont">
                        <div class="item flex" v-for="(item,index) in menuList" :key="index">
                            <div class="title flex flex-m flex-l"><span>{{ item.title }}</span></div>
                            <ul class="title-sub">
                                <li 
                                    v-for="(node,key) in item.children" 
                                    :key="key" 
                                    class="flex flex-m flex-l" 
                                    @mouseover="mouseoverMenu(node.id)" 
                                    :class="curIndex==node.id?'active':''"
                                    >
                                    <div class="msg flex"><span class="flex flex-m">{{ node.id+1 }}</span>{{ node.title }}<i class="el-icon-right _trans"></i></div>
                                    <div class="li-bg"><div class="img-bg"></div></div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="right flex-1 overflow myfont">
                        <swiper ref="mySwiper" :options="swiperOptions" class="my-swiper">
                            <swiper-slide v-for="(item,index) in contentList" :key="index" class="flex flex-m flex-t">
                                <div class="area">
                                    <div class="title">{{ item.title }}</div>
                                    <div class="content">{{ item.dec }}</div>
                                    <div class="link-box flex flex-between">
                                        <router-link 
                                            v-if="!$common.isEmpty(item.link)"
                                            tag="div" 
                                            class="link mr-4 _trans" 
                                            :to="item.link.indexOf('http')==-1?item.link:''" 
                                            @click.native="jumpOutUrl(item.link)">进入体验
                                        </router-link>
                                        <div>
                                            <el-popover
                                                v-if="!$common.isEmpty(item.wx)"
                                                placement="top"
                                                width="200"
                                                trigger="hover">
                                                <el-image fit="contain" :src="item.wx"></el-image>
                                                <div slot="reference" class="wx mr-4 _trans">小程序</div>
                                            </el-popover>
                                            <el-popover
                                                v-if="!$common.isEmpty(item.app)"
                                                placement="top"
                                                width="200"
                                                trigger="hover">
                                                <el-image fit="contain" :src="item.wx"></el-image>
                                                <div slot="reference" class="app mr-4 _trans">APP</div>
                                            </el-popover>
                                            <el-popover
                                                v-if="!$common.isEmpty(item.pad)"
                                                placement="top"
                                                width="200"
                                                trigger="hover">
                                                <el-image fit="contain" :src="item.wx"></el-image>
                                                <div slot="reference" class="pad mr-4 _trans">平板</div>
                                            </el-popover>
                                        </div>
                                    </div>
                                    <div class="img-box" v-if="item.images.length>0">
                                        <div class="mb-2">界面截图</div>
                                        <div class="image-preview">
                                            <el-image class="_trans" fit="contain" v-for="(node,key) in item.images" :key="key" :src="node" :preview-src-list="item.images"></el-image>
                                        </div>
                                    </div>
                                </div>
                            </swiper-slide>
                        </swiper>
                        <div class="swiper-pagination"></div>
                    </div>
                </div>
            </div>
            <div class="the-footer flex flex-m">
                <div class="mr-5">Copyright© 2020-2021 佛山市数乾科技有限公司 All Rights Reserved</div>
            </div>
        </div>
    </div>
</template>

<script>
    // import theHeader from "@/components/theHeader";
    // import theFooter from "@/components/theFooter";
    import productList from "@/assets/json/productList-sk.json"

    export default {
        components: {},
        props: {},
        data() {
            return {
                menuList: productList.data,
                curIndex: 0,
                swiperOptions: {
                    // pagination: {
                    //     el: '.swiper-pagination',
                    //     clickable :true
                    // },
                    speed: 300,
                    slidesPerView: 1,
                    on:{
                        slideChangeTransitionStart: ()=>{
                            this.curIndex = this.swiper.activeIndex
                        }
                    }
                }
            };
        },
        computed: {
            swiper() {
                return this.$refs.mySwiper.swiper
            },
            contentList(){
                var arr = []
                this.menuList.forEach((item,index) => {
                    item.children.forEach((node,key) => {
                        arr.push(node)
                    });
                });
                console.log(arr)
                return arr
            }
        },
        watch: {},
        methods: {
            mouseoverMenu(index) {
                this.curIndex = index
                this.swiper.slideTo(index, 200, false)
            },
            // 外链
			jumpOutUrl(url) {
				if(url.startsWith('http')){
					window.open(url, '_blank');
				}
			}
        },
        created() {
            // 动态配置浏览器图标
			var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
            link.type = 'image/x-icon';
            link.rel = 'shortcut icon';
            link.href = 'img/favicon-sk.ico';
            document.getElementsByTagName('head')[0].appendChild(link);
        },
        mounted() {
        },
        destroyed() {}
    };
</script>

<style lang="scss" scoped>
    .demo {
        height: 100%;
        background: url("~@/assets/demo-bg.jpg") center center no-repeat;
        background-size: cover;
        overflow: hidden;
        .the-header {
        height: 80px;
            flex: 0 0 80px;
            padding: 0 20px 0 0;
            border-bottom: 1px solid rgba($color: #fff, $alpha: 0.3);
            color: #fff;
        }
        .the-footer {
            margin-bottom: 20px;
            padding: 0 20px;
            // border-top: 1px solid rgba($color: #fff, $alpha: 0.3);
            color: rgba($color: #fff, $alpha: 0.6);
            a {
            color: rgba($color: #fff, $alpha: 0.6); 
            }
        }
        .bg {
            background-color: rgba($color: #000000, $alpha: 0.6);
            overflow: auto;
            .box {
                width: 100%;
                margin-top: 3%;
                padding: 40px 0;
                // border-top: 1px solid rgba($color: #fff, $alpha: 0.3);
                // border-bottom: 1px solid rgba($color: #fff, $alpha: 0.3);
                // background-color: rgba($color: #000000, $alpha: 0.2);
                color: #fff;
                .left {
                    margin-right: 50px;
                    // transform: perspective(1600px) rotateY(20deg);
                    // border-right: 1px solid rgba($color: #fff, $alpha: 0.3);
                    .item {
                        border-bottom: 1px solid rgba($color: #fff, $alpha: 0.5);
                        &:last-child {
                            border-bottom: 0;
                        }
                        .title {
                            width: 120px;
                            // border-right: 1px solid rgba($color: #fff, $alpha: 0.1);
                            font-size: 20px;
                            color: #62e1ff;
                            span {
                                margin-left: 20px;
                            }
                        }
                        .title-sub {
                            width: 350px;
                            li {
                                position: relative;
                                height: 70px;
                                padding: 20px 0 20px 20px;
                                // margin: 10px;
                                border-bottom: 1px solid rgba($color: #fff, $alpha: 0.1);
                                cursor: pointer;
                                &:last-child {
                                    border-bottom: 0;
                                }
                                .li-bg {
                                    position: absolute;
                                    left: 0;
                                    right: 0;
                                    top: 0;
                                    bottom: 0;
                                    transform: skewX(-15deg);
                                    // background-color: rgba($color: #000000, $alpha: 0.2);
                                    // background: linear-gradient(to right, rgba($color: #000, $alpha: 0.2) 0%, rgba($color: #000, $alpha: 0) 100%);
                                    // background: linear-gradient(to right, rgba($color: #001279, $alpha: 0.5) 0%, rgba($color: #172AB4, $alpha: 0) 100%);
                                    background: linear-gradient(to right, 
                                        rgba($color: #000000, $alpha: 0) 0%, 
                                        rgba($color: #000000, $alpha: 0.3) 50%,
                                        rgba($color: #000000, $alpha: 0) 100%
                                    );
                                    &::before {
                                        content: '';
                                        position: absolute;
                                        top: 0;
                                        left: 0;
                                        width: 100%;
                                        height: 1px;
                                    }
                                    &::after {
                                        content: '';
                                        position: absolute;
                                        bottom: 0;
                                        left: 0;
                                        width: 100%;
                                        height: 1px;
                                    }
                                    .img-bg {
                                        position: relative;
                                        width: 100%;
                                        height: 100%;
                                        overflow: hidden;
                                        &::before {
                                            content: '';
                                            position: absolute;
                                            top: 0;
                                            bottom: 0;
                                            left: 0;
                                            right: 0;
                                        }
                                    }
                                }
                                .msg {
                                    z-index: 2;
                                    position: relative;
                                    width: 100%;
                                    font-size: 20px;
                                    color: rgba($color: #fff, $alpha: 1);
                                    text-shadow: 0 2px 2px rgba($color: #000000, $alpha: 0.3);
                                    span {
                                        width: 26px;
                                        height: 26px;
                                        border-radius: 100%;
                                        margin-right: 10px;
                                        border: 3px solid rgba($color: #fff, $alpha: 0.3);
                                        font-size: 14px;
                                    }
                                    i {
                                        position: absolute;
                                        top: 50%;
                                        margin-top: -13px;
                                        right: 0;
                                        width: 26px;
                                        height: 26px;
                                        line-height: 26px;
                                        border-radius: 100%;
                                        text-align: center;
                                        // background-color: red;
                                        opacity: 0;
                                    }
                                }
                                // &:hover {
                                //     .bg {
                                //         background-color: rgba($color: #000000, $alpha: 0.4);
                                //     }
                                // }
                                &.active {
                                    .msg {
                                        color: rgba($color: #fff, $alpha: 1);
                                        // text-shadow: 0 0 10px rgba($color: #fff, $alpha: 1);
                                        i {
                                            right: 26px;
                                            opacity: 1;
                                        }
                                    }
                                    .li-bg {
                                        top: -1px;
                                        bottom: -1px;
                                        // border-top: 1px solid rgba($color: #00b3e9, $alpha: 1);
                                        // border-bottom: 1px solid rgba($color: #00b3e9, $alpha: 1);
                                        // background-color: rgba($color: #ff6600, $alpha: 1);
                                        background: linear-gradient(to right, rgba($color: #0065e9, $alpha: 1) 0%,rgba($color: #0065e9, $alpha: 0) 100%);
                                        &::before, &::after {
                                            background: linear-gradient(to right, rgba($color: #00c3ff, $alpha: 1) 0%,rgba($color: #00b3e9, $alpha: 0) 100%);
                                        }
                                        .img-bg {
                                            &::before {
                                                animation:mymove 10s linear infinite;
                                                background-image: url(~@/assets/fog-2.png);
                                            }
                                            @keyframes mymove
                                            {
                                                from {left:-1000px;}
                                                to {left:0;}
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .right {
                    position: relative;
                    .my-swiper {
                        .area {
                            flex: 0 0 60%;
                            .title {
                                position: relative;
                                padding-bottom: 40px;
                                margin-bottom: 40px;
                                font-size: 48px;
                                font-weight: bold;
                                &::before {
                                    content: '';
                                    position: absolute;
                                    left: 0;
                                    bottom: 0;
                                    height: 5px;
                                    width: 50px;
                                    background-color: #fff;
                                }
                            }
                            .content {
                                margin-bottom: 40px;
                                font-size: 16px;
                                line-height: 1.5;
                                text-indent: 2em;
                            }
                            .link-box {
                                margin-bottom: 40px;
                                .link {
                                    font-size: 18px;
                                    padding: 15px 120px 15px 120px;
                                    cursor: pointer;
                                    background-image: linear-gradient(135deg, #590fb7, #ff0076);
                                    // background-image: linear-gradient(135deg, #000066, #6699FF);
                                    background-size: 200%;
                                    &:hover {
                                        padding-right: 160px;
                                        background-position: 100% 0; 
                                    }
                                }
                                .wx, .app, .pad{
                                    font-size: 18px;
                                    padding: 15px 20px;
                                    border: 1px solid rgba($color: #fff, $alpha: 0.5);
                                    cursor: pointer;
                                    &:hover {
                                        border-color: rgba($color: #fff, $alpha: 0.8);
                                    }
                                }
                            }
                            .img-box {
                                color: rgba($color: #fff, $alpha: 1);
                                .el-image {
                                    width: 80px;
                                    height: 80px;
                                    margin-right: 10px;
                                    border: 1px solid rgba($color: #fff, $alpha: 0.3);
                                    background-color: rgba($color: #000000, $alpha: 0.3);
                                    /deep/ .el-image__inner {
                                        opacity: 0.6;
                                    }
                                    &:hover {
                                        border-color: rgba($color: #fff, $alpha: 0.9);
                                        background-color: rgba($color: #000000, $alpha: 0.1);
                                        /deep/ .el-image__inner {
                                            opacity: 1;
                                        }
                                    }
                                }
                            }
                        }
                        .swiper-pagination {
                            bottom: 0;
                        }
                    }
                }
            }
        }
    }
</style>
